import { render, staticRenderFns } from "./Button.vue?vue&type=template&id=2f3ea131&scoped=true&"
import script from "./Button.vue?vue&type=script&lang=js&"
export * from "./Button.vue?vue&type=script&lang=js&"
import style0 from "./Button.vue?vue&type=style&index=0&id=2f3ea131&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f3ea131",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/dilan/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2f3ea131')) {
      api.createRecord('2f3ea131', component.options)
    } else {
      api.reload('2f3ea131', component.options)
    }
    module.hot.accept("./Button.vue?vue&type=template&id=2f3ea131&scoped=true&", function () {
      api.rerender('2f3ea131', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/v2/SUI/Controls/Button/Button.vue"
export default component.exports